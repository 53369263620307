<!-- =========================================================================================
    File Name: movieCard.vue
    Description: Movie page card component
    ----------------------------------------------------------------------------------------
    Item Name: KOZO Admin - VueJS Dashboard
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div>
    <!-- GRID VIEW -->
    <div v-if="GridView" class="vx-row mt-10 bn_projectCard">
      <div
        class="vx-col w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mb-base evenSpace"
        v-for="(project, projectIndex) in visibleProjects"
        :key="projectIndex"
      >
        <div class="grid-view">
          <vx-card class="overflow-hidden project-cards">
            <!-- ****************** -->
            <div
              class="box d-flex align-items-center justify-content-between flex-column"
            >
              <div
                v-if="
                  project.project_icon ==
                  'https://api-applause.gokozo.com/uploads/primary-logo.png'
                "
                class="mb-3 project-image"
                style="
                  background: url('https://api-applause.gokozo.com/uploads/primary-logo.png');
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: contain;
                "
              >
                <!-- <img
                  :src="project.project_icon + '?token=' + tempToken"
                  class
                  alt
                /> -->
              </div>
              <div v-else class="mb-3 project-image">
                <img
                  :src="project.project_icon + '?token=' + tempToken"
                  class
                  alt
                />
              </div>
              <div class="project-info px-3">
                <h5 class="font-weight-bold truncate">
                  {{ project.project_name }}
                </h5>
                <p class="line-clamp">{{ project.project_details }}</p>
              </div>
              <div class="action-icons px-4 pb-3">
                <ul class="list-inline text-right mt-4">
                  <!-- v-if="
                      project.editUserType == 0 || project.editUserType == 2
                    " -->
                  <li
                    v-if="
                      project.editUserType == 0 ||
                      project.editUserType == 2 ||
                      permissionPageAccessArry.includes(
                        PermissionVariables.ViewOrganizationProjects
                      ) ||
                      loginUserRole == 'Admin'
                    "
                    class="list-inline-item font-semibold"
                  >
                    <router-link
                      :to="{
                        name: 'projectPosList',
                        params: { projectID: project.project_id },
                      }"
                    >
                      <feather-icon icon="ListIcon" svgClasses="h-7 w-7" />
                    </router-link>
                  </li>
                  <!-- v-if="(checkUserType!='0' && checkUserType!='2')" -->
                  <!-- v-if="
                      project.editUserType == 1 ||
                        (project.editUserType == 0 &&
                          !UserRoleCheck.includes('User') &&
                          !UserRoleCheck.includes('Moderator')) ||
                        project.editUserType == 2
                    " -->
                  <li
                    v-if="
                      (permissionPageAccessArry.includes(
                        PermissionVariables.projectEdit
                      ) ||
                        permissionPageAccessArry.includes(
                          PermissionVariables.viewEditSection
                        )) &&
                      permissionPageAccessArry.includes(
                        PermissionVariables.ViewOrganizationProjects
                      )
                        ? (permissionPageAccessArry.includes(
                            PermissionVariables.projectEdit
                          ) ||
                            permissionPageAccessArry.includes(
                              PermissionVariables.viewEditSection
                            )) &&
                          permissionPageAccessArry.includes(
                            PermissionVariables.ViewOrganizationProjects
                          )
                        : permissionPageAccessArry.includes(
                            '36P' + project.project_id
                          ) ||
                          loginUserRole == 'Admin' ||
                          permissionPageAccessArry.includes(
                            PermissionVariables.viewEditSection
                          )
                    "
                    class="list-inline-item font-semibold"
                  >
                    <router-link
                      :to="{
                        name: 'editProjectView',
                        params: { projectID: project.project_id },
                      }"
                    >
                      <feather-icon icon="EditIcon" svgClasses="h-7 w-7" />
                    </router-link>
                  </li>
                  <!-- v-if="
                      (project.editUserType == 0 ||
                        project.editUserType == 2) &&
                        UserRoleCheck.includes('Admin')
                    " -->
                  <li
                    v-if="
                      permissionPageAccessArry.includes(
                        PermissionVariables.projectArchive
                      ) &&
                      permissionPageAccessArry.includes(
                        PermissionVariables.ViewOrganizationProjects
                      )
                        ? permissionPageAccessArry.includes(
                            PermissionVariables.projectArchive
                          ) &&
                          permissionPageAccessArry.includes(
                            PermissionVariables.ViewOrganizationProjects
                          )
                        : permissionPageAccessArry.includes(
                            '37P' + project.project_id
                          ) || loginUserRole == 'Admin'
                    "
                    class="list-inline-item font-semibold"
                  >
                    <router-link to @click.native="onClickArchive(project)">
                      <feather-icon icon="ArchiveIcon" svgClasses="h-7 w-7" />
                    </router-link>
                  </li>
                  <!-- v-if="UserRoleCheck == 'Admin'" -->
                  <li
                    v-if="
                      permissionPageAccessArry.includes(
                        PermissionVariables.projectDelete
                      ) &&
                      permissionPageAccessArry.includes(
                        PermissionVariables.ViewOrganizationProjects
                      )
                        ? permissionPageAccessArry.includes(
                            PermissionVariables.projectDelete
                          ) &&
                          permissionPageAccessArry.includes(
                            PermissionVariables.ViewOrganizationProjects
                          )
                        : permissionPageAccessArry.includes(
                            '38P' + project.project_id
                          ) || loginUserRole == 'Admin'
                    "
                    class="list-inline-item font-semibold"
                  >
                    <router-link to @click.native="projectDelete(project)">
                      <feather-icon icon="Trash2Icon" svgClasses="h-7 w-7" />
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <!-- ******************* -->
          </vx-card>
        </div>
      </div>
    </div>
    <!-- LIST VIEW -->
    <div class="vx-row" v-if="ListView">
      <div class="vx-col w-full mb-base">
        <div class="list-view" id="data-list-list-view">
          <vs-table ref="table" pagination :max-items="4" :data="project">
            <template slot-scope="{ projects }">
              <tbody>
                <vs-tr
                  :data="project"
                  :key="indextr"
                  v-for="(project, indextr) in projects"
                >
                  <vs-td>
                    <!-- <img :src="imagePath+'?token='+tempToken" class alt /> -->
                    <span>{{ indextr + 1 }}</span>
                  </vs-td>

                  <vs-td>
                    <h5 class="font-weight-bold">{{ project.project_name }}</h5>
                    <p>{{ project.project_details }}</p>
                  </vs-td>

                  <vs-td>
                    <router-link
                      :to="{
                        name: 'projectPosList',
                        params: { projectID: project.project_id },
                      }"
                    >
                      <feather-icon icon="ListIcon" svgClasses="h-7 w-7" />
                    </router-link>
                  </vs-td>

                  <vs-td>
                    <router-link
                      :to="{
                        path: '/editProjectView',
                        params: { projectID: project.project_id },
                      }"
                    >
                      <feather-icon icon="EditIcon" svgClasses="h-7 w-7" />
                    </router-link>
                  </vs-td>

                  <vs-td>
                    <router-link to @click.native="getAllArchiveProjects()">
                      <feather-icon icon="ArchiveIcon" svgClasses="h-7 w-7" />
                    </router-link>
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
      </div>
    </div>
    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalS'"
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Delete Project"
        :active.sync="popupActive"
      >
        <div class="text-cent er col-12">
          <h5>
            Do you want to delete {{ project_detail.project_name }} project ?
          </h5>
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus" -->
              <vs-button
                color="success"
                type="filled"
                @click="deleteProjectNew()"
                class="mr-3"
                >Yes</vs-button
              >
              <vs-button
                color="danger"
                type="filled"
                @click="closeModalDelete()"
                >No</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import projectService from "@/services/projectService.js";
import PermissionVariables from "@/services/UserManagementVaraibles.js";

export default {
  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      PermissionVariables: PermissionVariables,
      loginUserRole: window.localStorage.getItem("UserRole"),
      archive: this.listType,
      projects: [],
      visibleProjects: [],
      tempToken: window.localStorage.getItem("TempToken"),
      project_detail: {
        project_name: "",
      },
      popupActive: false,
    };
  },
  props: {
    listType: {},
    gridView: {
      type: Boolean,
      default: true,
    },
    listView: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    // icon: {
    //   type: String,
    //   required: true
    // },
    // statistic: {
    //   type: [Number, String],
    //   required: true
    // },
    statisticTitle: {
      type: String,
    },
    // chartData: {
    //   type: Object,
    //   // required: true,
    //   default: {}
    // },
    color: {
      type: String,
      default: "primary",
    },
    chartType: {
      type: String,
      default: "line-chart",
    },
    type: {
      type: String,
      default: "line",
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    titleName: {
      type: String,
      default: "",
    },
    deptName: {
      type: String,
      default: "",
    },
    deptNum: {
      type: String,
      default: "0",
    },
    imagePath: {
      type: String,
      default: "",
    },
  },
  watch: {
    themePrimaryColor() {
      this.$refs.apexChart.updateOptions({
        theme: { monochrome: { color: this.getHex() } },
      });
    },
  },
  beforeMount: function () {
    this.getProjectByAdminManager();
  },
  mounted() {
    eventBus.$on("filterProject", (e) => {
      if (e != "") {
        let search = e.toLowerCase();
        this.visibleProjects = this.projects.filter((obj) => {
          return obj.project_name.toLowerCase().includes(search);
        });
      } else {
        this.visibleProjects = this.projects;
      }
    });
    this.getProjectsList();
  },
  computed: {
    checkUserType() {
      return window.localStorage.getItem("UserType");
    },

    UserRoleCheck() {
      return window.localStorage.getItem("UserRole");
    },

    Items() {
      return this.items;
    },
    themePrimaryColor() {
      return this.$store.state.themePrimaryColor;
    },
    GridView() {
      return this.gridView;
    },
    ListView() {
      return this.listView;
    },
  },

  methods: {
    getToken() {
      return window.localStorage.getItem("TempToken");
    },
    closeModalDelete() {
      this.popupActive = false;
    },
    projectDelete(project) {
      console.log(project);
      this.project_detail = project;
      this.popupActive = true;
      //   this.popupActive=true;
    },
    deleteProjectNew: function () {
      // payload.project_active = project.project_active == "4" ? "1" : "4";
      projectService
        .deleteProject(this.project_detail.project_id)
        .then((response) => {
          const { data } = response;
          if (data && data.Status == true) {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            this.getProjectsList();
          } else {
            this.$vs.notify({
              title: "OOPS!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
          this.popupActive = false;
        })
        .catch((err) => {
          this.popupActive = false;
        });
    },

    onClickArchive: function (project) {
      // console.log(project);
      // return false;
      let payload = {
        ...project,
      };

      payload.project_active = project.project_active == "4" ? "1" : "4";
      projectService
        .editProject(payload, project.project_id)
        .then((response) => {
          const { data } = response;
          if (data && data.Status == true) {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            this.getProjectsList();
          } else {
            this.$vs.notify({
              title: "OOPS!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((err) => {});
    },
    getProjectsList: function () {
      this.$vs.loading();
      this.projects = [];
      let payload = {};
      if (this.archive == "archive") {
        payload.archive = true;
      }
      projectService
        .getAllProjectsByUserRole(payload)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.projects = data.data;
            if (
              !this.permissionPageAccessArry.includes(
                PermissionVariables.ViewOrganizationProjects
              ) &&
              this.loginUserRole != "Admin"
            ) {
              this.projects.map((x) => {
                if (
                  this.permissionPageAccessArry.includes("33P" + x.project_id)
                ) {
                  this.visibleProjects.push(x);
                }
              });
            } else {
              this.visibleProjects = this.projects;
            }

            //this.visibleProjects = this.projects;
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getProjectByAdminManager: function () {},
    getHex() {
      let rgb = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue(`--vs-${this.color}`);
      rgb = rgb.split(",");
      return (
        "#" +
        (
          (1 << 24) +
          (Number(rgb[0]) << 16) +
          (Number(rgb[1]) << 8) +
          Number(rgb[2])
        )
          .toString(16)
          .slice(1)
      );
    },
  },

  components: {
    VueApexCharts,
  },
  created() {
    // if (this.type == "area") {
    //   this.chartData && this.chartData["chartOptions"]
    //     ? (this.chartData.chartOptions["theme"] = {
    //         monochrome: {
    //           enabled: true,
    //           color: this.getHex(this.color),
    //           shadeTo: "light",
    //           shadeIntensity: 0.65
    //         }
    //       })
    //     : "";
    // }
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
