<!-- =========================================================================================
    File Name: projectCard.vue
    Description: Projects page card component
    ----------------------------------------------------------------------------------------
    Item Name: KOZO Admin - VueJS Dashboard
    Author:
    Author URL:
========================================================================================== -->

<template>
  <vx-card v-on:click="goToRoute()" class="overflow-hidden">
    <div slot="no-body">
      <div
        class="p-5 cus-anim"
        :class="{
          'flex justify-between flex-row-reverse items-center': iconRight,
        }"
      >
        <div class="d-flex align-items-center">
          <feather-icon
            :icon="icon"
            class="p-3 inline-flex rounded-full"
            :class="[`text-${color}`]"
            :style="{ background: `rgba(var(--vs-${color}),.15)` }"
          ></feather-icon>
          <h4 class="mb-0 font-bold ml-3">{{ titleName }}</h4>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  props: {
    routerLink: {
      type: String,
    },
    icon: {
      type: String,
      required: true,
    },
    statisticTitle: {
      type: String,
    },
    chartData: {
      type: Object,
      required: false,
      // default: {}
    },
    color: {
      type: String,
      default: "primary",
    },
    chartType: {
      type: String,
      default: "line-chart",
    },
    type: {
      type: String,
      default: "line",
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    titleName: {
      type: String,
      default: "",
    },
    deptName: {
      type: String,
      default: "",
    },
    deptNum: {
      type: String,
      default: "0",
    },
  },

  components: {
    VueApexCharts,
  },

  watch: {
    themePrimaryColor() {
      this.$refs.apexChart.updateOptions({
        theme: { monochrome: { color: this.getHex() } },
      });
    },
  },

  computed: {
    themePrimaryColor() {
      return this.$store.state.themePrimaryColor;
    },
  },

  methods: {
    goToRoute: function() {
      this.$router.push({
        path: this.routerLink,
      });
    },
    getHex() {
      let rgb = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue(`--vs-${this.color}`);
      rgb = rgb.split(",");
      return (
        "#" +
        (
          (1 << 24) +
          (Number(rgb[0]) << 16) +
          (Number(rgb[1]) << 8) +
          Number(rgb[2])
        )
          .toString(16)
          .slice(1)
      );
    },
  },

  created() {
    if (this.type == "area") {
      this.chartData && this.chartData["chartOptions"]
        ? (this.chartData.chartOptions["theme"] = {
            monochrome: {
              enabled: true,
              color: this.getHex(this.color),
              shadeTo: "light",
              shadeIntensity: 0.65,
            },
          })
        : "";
    }
  },
};
</script>
